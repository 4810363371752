<template>
  <div class="total board_box">
    <div class="board_title dp_f">
      <div class="title">库存预警</div>
        <i class="collection iconfont icon-a-ziyuan16 cur_p"  @click='like()' v-if='isLike'/>
     <i class="collection iconfont icon-a-ziyuan15 cur_p" @click='like(true)' v-else/>
    </div>
    <div class="content dp_f cur_p" @click="goTab">
      <div class="item item1">
        <div class="top">
          <div class="text">需补货产品（种）</div>
          <div class="num">{{orderInfo.replenishment}}</div>
        </div>
        <div class="bottom">
        </div>
      </div>
      <div class="item item2">
        <div class="top">
          <div class="text-top">可销售<span class="time">3天</span></div>
        </div>
        <div class="bottom">
            <div class="text mt">产品预估（种）</div>
            <div class="num">{{orderInfo.replenishmentThree}}</div>
        </div>
      </div>
      <div class="item item3">
        <div class="top">
          <div class="text-top">可销售<span class="time">7天</span></div>
        </div>
        <div class="bottom">
            <div class="text mt">产品预估（种）</div>
            <div class="num">{{orderInfo.replenishmentSeven}}</div>
        </div>
      </div>
      <div class="item item4">
        <div class="top">
          <div class="text">其他需补货产品（种）</div>
          <div class="num">{{orderInfo.replenishmentOther}}</div>
        </div>
        <div class="bottom">
      </div>
    </div>
  </div>
  </div>
</template>
<script setup>
import {getCurrentInstance, reactive, ref, toRefs, watch} from "vue";
import {stockCensusreplenishment} from "@/utils/api/inStock/board.js";
import moment from 'moment'

const props = defineProps({
  formData: {
    type: Object,
    default: () => {},
  },
  isLike: {
    type: Boolean,
    default: false,
  },
});
const { proxy } = getCurrentInstance();
const unitId = ref(12);
const emit = defineEmits(["like"])
const { formData, isLike } = toRefs(props);
const orderInfo = reactive({
  replenishment: 0, // 需补货产品
  replenishmentThree: 0, // 可销售3天
  replenishmentSeven: 0, // 可销售7天
  replenishmentOther: 0, // 其他需补货产品
})
watch(
  () => formData.value,
  (val) => {
    getData()
  },{
    deep:true
  }
);
const getData = () => {
  stockCensusreplenishment({
       beginTime:formData.value.time && formData.value.time[0] ? moment(formData.value.time[0]).valueOf() : 0,
    endTime:formData.value.time && formData.value.time[1] ? moment(formData.value.time[1]).valueOf() : 0,
    stockId:formData.value.stockId || 0,
  }).then((res) => {
      if(res.code != 0){
        proxy.$message.error(res.msg)
        return
      }
     orderInfo.replenishment = res.data?.replenishment || 0;
     orderInfo.replenishmentThree = res.data?.replenishmentThree || 0;
     orderInfo.replenishmentSeven = res.data?.replenishmentSeven || 0;
     orderInfo.replenishmentOther = res.data?.replenishmentOther || 0;
  })
};
getData()
const like = (type = false)=>{
  emit("like",{
    unitId:unitId.value,
    type
  } );
}
const goTab = ()=>{
  proxy.$router.push({
    path:`/inStock/inventoryCheck`,
    query:{
      tabChecked:"InventoryWarning"
    }
  })
}
</script>

<script>
export default {
  name: "total",
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
.total {
  width: 49.5%;
  height: 296px;
  .content {
    margin-top: 16px;
    .item {
      height: 192px;
      padding: 22px 0 0 22px;
      box-sizing: border-box;
      border-radius: 16px;
      @include composing(4, 1%);
      &.item1 {
        background: url("../../../assets/img/inStock/bg-1.png") center
          center no-repeat;
        background-size: auto;
      }
      &.item2 {
        background: url("../../../assets/img/inStock/bg-2.png") center
          center no-repeat;
        background-size: auto;
      }
      &.item3 {
        background: url("../../../assets/img/inStock/bg-3.png") center
          center no-repeat;
        background-size: auto;
      }
      &.item4 {
        background: url("../../../assets/img/inStock/bg-4.png") center
          center no-repeat;
        background-size: auto;
      }
      .num {
        font-size: 24px;
        color: $fsColor;
        @include textOverflow(100%);
        .unit {
          font-size: 18px;
        }
      }
      .text {
        font-size: 14px;
        color: $fsColor2;
        margin-top: 10px;
      }
      .text-top{
        margin-top: 4px;
        .time{
          font-size: 18px;
        }
      }
      .bottom {
        margin-top: 30px;
      }
      .num{
        font-weight: bold;
        margin-top: 12px;
        color: #161829;
        @include textOverflow(100%);
      }
    }
  }
}
</style>
