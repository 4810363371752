<template>
  <div class="timeWarning board_box">
    <div class="board_title dp_f">
      <div class="title">效期预警</div>
        <i class="collection iconfont icon-a-ziyuan16 cur_p"  @click='like()' v-if='isLike'/>
     <i class="collection iconfont icon-a-ziyuan15 cur_p" @click='like(true)' v-else/>
    </div>
    <div class="content cur_p dp_f" @click="goTab">
      <div class="timeWarning-single">
        <img src="@/assets/img/inStock/time-product.png" class="time-pic" />
        <div class="time-text">临期产品（种）</div>
        <div class="time-num">{{orderInfo.expiringSoonNum}}</div>
      </div>
      <div class="timeWarning-single">
        <img src="@/assets/img/inStock/time-product.png" class="time-pic" />
        <div class="time-text">合计标签数量</div>
        <div class="time-num">{{orderInfo.total}}</div>
      </div>
      <div class="timeWarning-single">
        <img src="@/assets/img/inStock/time-product.png" class="time-pic" />
        <div class="time-text">合计金额（万元）</div>
        <div class="time-num">{{$filters.toYuan(orderInfo.totalPrice,'万元')}}</div>
      </div>
    </div>
  </div>
</template>
<script setup>
import {getCurrentInstance, reactive, ref, toRefs, watch} from "vue";
import {stockCensusExpire} from "@/utils/api/inStock/board.js";
import moment from 'moment'

const props = defineProps({
  formData: {
    type: Object,
    default: () => {},
  },
  isLike: {
    type: Boolean,
    default: false,
  },
});
const { proxy } = getCurrentInstance();
const unitId = ref(16);
const emit = defineEmits(["like"])
const { formData, isLike } = toRefs(props);
const orderInfo = reactive({
  expiringSoonNum: 0, // 滞销产品数量
  total: 0, //合计数量
  totalPrice: 0, //合金金额
})
watch(
  () => formData.value,
  (val) => {
    getData()
  },{
    deep:true
  }
);
const getData = () => {
  stockCensusExpire({
       beginTime:formData.value.time && formData.value.time[0] ? moment(formData.value.time[0]).valueOf() : 0,
    endTime:formData.value.time && formData.value.time[1] ? moment(formData.value.time[1]).valueOf() : 0,
    stockId:formData.value.stockId || 0,
  }).then((res) => {
      if(res.code != 0){
        proxy.$message.error(res.msg)
        return
      }
     orderInfo.expiringSoonNum = res.data?.expiringSoonNum || 0;
     orderInfo.total = res.data?.total || 0;
     orderInfo.totalPrice = res.data?.totalPrice || 0;
  })
};
getData()
const like = (type = false)=>{
  emit("like",{
    unitId:unitId.value,
    type
  } );
}
const goTab = ()=>{
  proxy.$router.push({
    path:`/inStock/inventoryCheck`,
    query:{
      tabChecked:"validityWarning"
    }
  })
}
</script>

<script>
export default {
  name: "total",
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
.timeWarning {
  width: 49.5%;
  height: 296px;
  .content {
    margin-top: 50px;
  .timeWarning-single{
    flex: 1;
    .time-pic{
      width:36px;
      height: 36px;
      overflow: hidden;
    }
    .time-text{
      line-height: 1.3;
      margin-top: 30px;
      color: #737480;
      font-size: 14px;
    }
    .time-num{
      font-size: 24px;
      font-weight: bold;
      margin-top: 18px;
      @include textOverflow(200px)
    }
  }
  }
}
</style>
