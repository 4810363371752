<template>
  <div class="total board_box">
    <div class="board_title dp_f">
      <div class="title">库存合计</div>
        <i class="collection iconfont icon-a-ziyuan16 cur_p"  @click='like()' v-if='isLike'/>
     <i class="collection iconfont icon-a-ziyuan15 cur_p" @click='like(true)' v-else/>
    </div>
    <div class="content cur_p" @click="goTab">
      <div class="total-box dp_f">
        <div class="total-single">
          <div class="total-single-title">库存产品</div>
          <div class="total-single-num">{{orderInfo.totalStock.inStockProducts}}</div>
        </div>
        <div class="total-single">
          <div class="total-single-title">库存数量</div>
          <div class="total-single-num">{{orderInfo.totalStock.inStockNum}}</div>
        </div>
        <div class="total-single">
          <div class="total-single-title">库存金额</div>
          <div class="total-single-num">{{$filters.toYuan(orderInfo.totalStock.inStockPrice,'')}}</div>
        </div>
      </div>
      <div class="cens-box dp_f">
        <div class="cens-single nomarl">
          <div class="cens-l">
            <img src="../../../assets/img/inStock/nomarl.png" class="cens-pic" />
            <div class="cens-title">正常库存</div>
          </div>
          <div class="cens-r">
            <div class="item">
              <span class="title">库存产品：</span><span class="num">{{orderInfo.successStock.inStockProducts}}</span>
            </div>
            <div class="item center">
              <span class="title">库存数量：</span><span class="num">{{orderInfo.successStock.inStockNum}}</span>
            </div>
            <div class="item">
              <span class="title">库存金额：</span><span class="num">{{$filters.toYuan(orderInfo.successStock.inStockPrice,'')}}</span>
            </div>
          </div>
        </div>
        <div class="cens-single bad">
          <div class="cens-l">
            <img src="../../../assets/img/inStock/bad.png" class="cens-pic" />
            <div class="cens-title">异常库存</div>
          </div>
          <div class="cens-r">
           <div class="item">
              <span class="title">库存产品：</span><span class="num">{{orderInfo.errorStock.inStockProducts}}</span>
            </div>
            <div class="item center">
              <span class="title">库存数量：</span><span class="num">{{orderInfo.errorStock.inStockNum}}</span>
            </div>
            <div class="item">
              <span class="title">库存金额：</span><span class="num">{{$filters.toYuan(orderInfo.errorStock.inStockPrice,'')}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import {getCurrentInstance, reactive, ref, toRefs, watch} from "vue";
import {stockCensusTotal} from "@/utils/api/inStock/board.js";
import moment from 'moment'

const props = defineProps({
  formData: {
    type: Object,
    default: () => {},
  },
  isLike: {
    type: Boolean,
    default: false,
  },
});
const { proxy } = getCurrentInstance();
const unitId = ref(15);
const emit = defineEmits(["like"])
const { formData, isLike } = toRefs(props);
const orderInfo = reactive({
  successStock:{
    inStockProducts:0,
    inStockNum:0,
    inStockPrice:0,
  },//正常库存信息
  errorStock:{
     inStockProducts:0,
    inStockNum:0,
    inStockPrice:0,
  },//异常库存信息
  totalStock:{
   inStockProducts:0,
    inStockNum:0,
    inStockPrice:0,
  },//总库存信息
})
watch(
  () => formData.value,
  (val) => {
    getData()
  },{
    deep:true
  }
);
const getData = () => {
  stockCensusTotal({
       beginTime:formData.value.time && formData.value.time[0] ? moment(formData.value.time[0]).valueOf() : 0,
    endTime:formData.value.time && formData.value.time[1] ? moment(formData.value.time[1]).valueOf() : 0,
    stockId:formData.value.stockId || 0,
  }).then((res) => {
      if(res.code != 0){
        proxy.$message.error(res.msg)
        return
      }
     orderInfo.successStock = res.data?.successStock || {};
     orderInfo.errorStock = res.data?.errorStock || {};
     orderInfo.totalStock = res.data?.totalStock || {};
  })
};
getData()
const like = (type = false)=>{
  emit("like",{
    unitId:unitId.value,
    type
  } );
}
const goTab = ()=>{
  proxy.$router.push({
    path:`/inStock/inventoryCheck`,
  })
}
</script>

<script>
export default {
  name: "total",
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
.total {
  width: 49.5%;
  height: 296px;
  .content {
    margin-top: 26px;
    .total-box{
      .total-single{
        display: flex;
        align-items: center;
        .total-single-title{
          font-size: 14px;
          color: $fsColor2;
        }
        .total-single-num{
          font-size: 24px;
          color: $fsColor;
          margin-left: 8px;
          @include textOverflow(200px);
        }
  }
    }
    .cens-box{
      margin-top: 30px;
      .cens-single{
        width: calc(calc(100%/2) - 8px) ;
        border-radius: 16px;
        height: 136px;
        display: flex;
        align-items: center;
        &.nomarl{
          background-color: rgba(72, 203, 159,0.1);
        }
        &.bad{
          background: rgba(255, 145, 10,0.1);
        }
        .cens-l{
          width: 35%;
          text-align: center;
          .cens-pic{
            width: 18px;
            height: 18px;
          }
          .cens-title{
            margin-top: 10px;
            font-weight: bold;
            color: $fsColor;
            font-size: 14px;
          }
        }
        .cens-r{
          .item{
            @include textOverflow(200px);
            line-height: 1.2;
            .title{
              color: #737480;
            }
            .num{
              color: #161829;
            }
          }
            >.center{
              margin: 18px 0;
            }
        }
      }
    }

  }
}
</style>
