import request from '../../http'
import {managerUrl} from '../../api'

//库存分类占比
export function stockCensusClassify(data){
  return request({
    url:managerUrl + "/carton/product/stock/in/census/classify",
    method:'post',
    data
  })
}

//库存合计
export function stockCensusTotal(data){
  return request({
    url:managerUrl + "/carton/product/stock/in/census/total",
    method:'post',
    data
  })
}

//库存预警
export function stockCensusreplenishment(data){
  return request({
    url:managerUrl + "/carton/product/stock/in/census/replenishment",
    method:'post',
    data
  })
}

//库存产品排名
export function stockCensusRank(data){
  return request({
    url:managerUrl + "/carton/product/stock/in/census/product",
    method:'post',
    data
  })
}

//效期预警
export function stockCensusExpire(data){
  return request({
    url:managerUrl + "/carton/product/stock/in/census/validity",
    method:'post',
    data
  })
}

//滞销预警
export function stockCensusUnsalable(data){
  return request({
    url:managerUrl + "/carton/product/stock/in/census/unsalable",
    method:'post',
    data
  })
}
